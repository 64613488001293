<template>
    <div class="home">
        <div class="w-100 overflow-hidden banner-container d-flex justify-content-center align-center justify-center" style="height: 580px">
            <div class="banner-overlay"></div>
            <img class="banner" :style="'transform: translate3d(0px, ' + ($store.state.window.offsetY / 3) + 'px, 0px);'"  alt="banner" src="../assets/img/bg-banner-2.jpg">
            <div class="banner-content" :style="'filter: blur(' + ($store.state.window.offsetY / 150) + 'px);'">
                <div class="banner-content-in container mx-auto">
                    <h1 class="home-title">{{$store.state.language.home.title}}</h1>
                    
                    <p style="text-align:center !important">{{$store.state.language.home.slogan}}</p>
                </div>
                
            </div>
        </div>
        <div class="test-section">
            <div class="d-flex px-8">
                <div v-if="$store.state.window.pageWidth < 960 ? false : true">
                    <img :src="require('../assets/img/img-1.jpg')" width="600" height="650" style="border-radius:12px">
                </div>
                <div class="pl-4 pl-md-12">
                    <div class="details">
                        <h2>{{$store.state.language.home.aboutUs.title}}</h2>
                        <p>{{$store.state.language.home.aboutUs.p1}}</p>
                        <p>{{$store.state.language.home.aboutUs.p2}}</p>
                        <p>{{$store.state.language.home.aboutUs.p3}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!--
        <div class="contact-section">
            <img class="banner" :style="'transform: translate3d(0px, ' + (($store.state.window.offsetY / 5) - 340) + 'px, 0px) scale(1.' + scaleNumber + ');'"  alt="banner" src="../assets/img/contact-image.jpg">
            <div class="banner-content">
            </div>
        </div>
        -->
        <div class="products-section d-flex align-center justify-center">
            <div class="content d-flex flex-column w-100">
                
                <div class="d-flex flex-wrap ">
                    <div class="flex-col">
                        <div class="software-card software-card-empty" style="background: transparent !important">
                            <div class="d-flex flex-column">
                                <h2 class="mt-0 mt-md-8">{{$store.state.language.home.products.title}}</h2>
                                <p>{{$store.state.language.home.products.desc}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col">
                        <div  class="software-card">
                            <div class="software-card-image-div">
                                <img :src="require('@/assets/logos/pscad.png')" width="100%" style="border-radius: 5px">
                            </div>
                            <div class="content">
                                <h3 style="font-size: 19px; margin-top: 30px; margin-bottom: 10px; font-weight: 600"><a style="color: #1f1f25;">PSCAD</a></h3>
                                <a class="btn-transparent rn-btn-dark cursor-pointer" @click="$router.push('/products/pscad')"><span class="text">{{$store.state.language.home.products.readMore}}</span></a>
                                </div>
                        </div>
                    </div>
                    <div class="flex-col">
                        <div  class="software-card">
                            <div class="software-card-image-div">
                                <img :src="require('@/assets/logos/rtds.png')" width="100%" style="border-radius: 5px">
                            </div>
                            <div class="content">
                                <h3 style="font-size: 19px; margin-top: 30px; margin-bottom: 10px; font-weight: 600"><a style="color: #1f1f25;">RTDS</a></h3>
                                <a class="btn-transparent rn-btn-dark cursor-pointer" @click="$router.push('/products/rtds')"><span class="text">{{$store.state.language.home.products.readMore}}</span></a>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col">
                        <div  class="software-card">
                            <div class="software-card-image-div">
                                <img :src="require('@/assets/logos/enerplot.png')" width="100%" style="border-radius: 5px">
                            </div>
                            <div class="content">
                                <h3 style="font-size: 19px; margin-top: 30px; margin-bottom: 10px; font-weight: 600"><a style="color: #1f1f25;">Enerplot</a></h3>
                                <a class="btn-transparent rn-btn-dark cursor-pointer" @click="$router.push('/products/enerplot')"><span class="text">{{$store.state.language.home.products.readMore}}</span></a>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col">
                        <div  class="software-card">
                            <div class="software-card-image-div">
                                <img :src="require('@/assets/logos/face.png')" width="100%" style="border-radius: 5px">
                            </div>
                            <div class="content">
                                <h3 style="font-size: 19px; margin-top: 30px; margin-bottom: 10px; font-weight: 600"><a style="color: #1f1f25;">FACE</a></h3>
                                <a class="btn-transparent rn-btn-dark cursor-pointer" @click="$router.push('/products/face')"><span class="text">{{$store.state.language.home.products.readMore}}</span></a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="flex-col">
                        <div  class="software-card">
                            <div class="software-card-image-div">
                                <img :src="require('@/assets/logos/prsim.png')" width="100%" style="border-radius: 5px">
                            </div>
                            <div class="content">
                                <h3 style="font-size: 19px; margin-top: 30px; margin-bottom: 10px; font-weight: 600"><a style="color: #1f1f25;">PRSIM</a></h3>
                                <a class="btn-transparent rn-btn-dark cursor-pointer" @click="$router.push('/products/prsim')"><span class="text">{{$store.state.language.home.products.readMore}}</span></a>
                            </div>
                        </div>
                    </div>

                    <div v-if="false" class="flex-col">
                        <div  class="software-card">
                            <div class="software-card-image-div">
                                <img :src="require('@/assets/logos/ensi.png')" width="100%" style="border-radius: 5px">
                            </div>
                            <div class="content">
                                <h3 style="font-size: 19px; margin-top: 30px; margin-bottom: 10px; font-weight: 600"><a style="color: #1f1f25;">ENSI</a></h3>
                                <a class="btn-transparent rn-btn-dark cursor-pointer" @click="$router.push('/products/ensi')"><span class="text">{{$store.state.language.home.products.readMore}}</span></a>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                

            </div>
        </div>
        <FooterContact></FooterContact>
        <!--
        
        -->
        
    </div>
</template>

<script>

import FooterContact from '@/components/FooterContact'
export default {
    name: 'Home',
    components:{
        FooterContact
    },
    computed: {
        scaleNumber(){
            let value = String(this.$store.state.window.offsetY);
            value = value.substr(0, value.indexOf('.')) || value;
            
            value = value.substring(0,4);
            value = ('0000' + value).slice(-4)
            console.log(value);
            return value
        },
    },
    methods: {
        downloadFile: function(fileName){
            var link = document.createElement("a");
            // If you don't know the name or want to use
            // the webserver default set name = ''
            link.setAttribute('{{$store.state.language.home.products.download}}', name);
            link.href = 'https://leanpowersolutions.com/documents/' + fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }
}
</script>
<style scoped>
    .banner-container{
        position: relative;
    }
    .banner {
        object-fit: cover;
        object-position: 50% 50%;
        max-width: none;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        pointer-events: none;
        position: relative;
        margin-top: 0px;
    }
    .banner-overlay:before {
        content:"";
        position: absolute;
        top:0;
        right:0;
        left:0;
        bottom:0;
        z-index:1;
        background:linear-gradient(to right,rgba(0, 0, 0, 0.8) ,rgba(0, 0, 0, 0.9));
    }
    .banner-content{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        z-index: 36;
        background-color: transparent;
        position: absolute;
        top: 0;
        color: white;
    }
    .banner-content-in p{


        font-size: 22px;
        line-height: 27px;
        color: #c6c9d8;

        margin-top: 25px;
    }
    
    .banner-content-in > .home-title{
        font-size: 45px !important;
        background: #f81f01 !important;
        background: -webkit-linear-gradient(145deg,#c3961e,#fabb11) !important;
        background: -webkit-linear-gradient(305deg,#c3961e,#fabb11) !important;
        background: linear-gradient(145deg,#c3961e,#fabb11) !important;
        background: -webkit-linear-gradient(349deg#c3961e#fabb11) !important;
        background: linear-gradient(-259deg,#c3961e#fabb11) !important;
        font-weight: 700;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        display: inline-block !important;
        margin-bottom: 0px;
    }

    @media only screen and (min-width: 600px) {
        .banner-content-in > .home-title{
            font-size: 72px !important;
        }
    }



    .test-section{
        width: 100%;
        max-width: 1300px;
        margin: 20px auto;
    }
    @media only screen and (min-width: 600px) {
        .test-section{
            margin: 100px auto;
        }
    }
    .test-section .details{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .test-section h2{
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 25px;
        letter-spacing: 5px;
        text-shadow: -2px 0px 0px rgb(251 188 17 / 0%);
    }
    .test-section p{
        color: rgba(29,29,36,.75);
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 0;
    }

    .contact-input{
        border: none;
        border: 1px solid #ffffff;
        padding: 13px;
        outline: none;
        border-radius: 3px;
        width: 350px;
        font-size: 13px;
        font-family: 'Poppins', sans-serif !important;
        resize: none;
    }
    textarea.contact-input{
        height: 120px;
        font-family: 'Poppins', sans-serif !important;
    }



    .products-section{
        background: #f8f9fc;
        padding: 50px 10px;
    }
    .products-section .content{
        max-width: 1300px;
    }
    .products-section h2{
        font-size: 54px;
        font-family: "Poppins", sans-serif;
        line-height: 1.4074;
        color: #1f1f25;
        margin: 0;
    }
    .products-section p{
        font-size: 20px;
        line-height: 30px;
        color: rgba(29, 29, 36, 0.75);
    }

    .software-card{
        padding: 45px 45px;
        z-index: 2;
        border-radius: 10px;
        display: flex;
        background: #f6f6f6;
        position: relative;
        flex-direction: column;
        margin: 10px;
    }
    .software-card .icon{
        font-size: 54px;
        font-weight: 400;
        margin-bottom: 23px;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        color: #e4ac16;
        display: none;
    }
    .software-card .icon svg{
        width: 54px;
        height: 54px;
            stroke-width: 1 !important;
    }
    .software-card .title{
        margin-top: 5px;
        margin-bottom: 0 !important;
        line-height: 2rem;
        letter-spacing: 0.0125em !important;
        font-size: 28px !important;
        font-weight: 500;
        font-size: 24px;
    }
    .software-card p{
            color: rgba(29, 29, 36, 0.75);
    }
    .software-card-image-div{
        height: 200px;
        position: relative;
        max-width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }



    a.btn-transparent{
        color: #1f1f25;
        font-size: 16px;
        font-weight: 500;
        position: relative;
        display: inline-block;
        padding-bottom: 3px;
        transition: .3s;
        text-decoration: none;
    }
    a.btn-transparent:after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 40px;
        height: 2px;
        background: #e4ac16;
        opacity: .75;
        transition: .3s;
    }
    a.btn-transparent:hover {
        color: #e4ac16;
    }
    a.btn-transparent:hover:after {
        width: 100%;
    }
    .flex-col{
        width: 32%;
    }

    .software-card-empty{
        padding: 45px !important;
        text-align: left !important;
    }
    
    @media (max-width: 980px) {
        .flex-col {
            width: 50%;
        }
    }
    @media (max-width: 600px) {
        .flex-grid {
            display: block;
        }
        .flex-col {
            width: 100%;
        }
        .software-card-empty{
            padding: 0px !important;
            text-align: center !important;
        }
    }
    
</style>
<style>
    .doc-text{color: rgba(29,29,36,.75) !important}
</style>