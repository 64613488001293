import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const checkLanguage = () => {
	return new Promise(resolve => {
		
		if(!store.state.languages.find(x=>x.code === localStorage.getItem("languageCode"))){
			localStorage.setItem("languageCode", 'EN');
			store.state.languageCode = 'EN'
		}

		if(!store.state.languageCode && !(localStorage.getItem("languageCode"))){
			localStorage.setItem("languageCode", 'EN');
			store.state.languageCode = 'EN'
		}else{
			store.state.languageCode = localStorage.getItem("languageCode")
		}
		if(store.state.language == null){
			switch (store.state.languageCode) {
				case 'EN':
					store.state.language = require('../languages/EN.json')
					break;
				case 'TR':
					store.state.language = require('../languages/TR.json')
					break;
				default:
					break;
			}
		}
		resolve(true)
	});
}

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/services',
        name: 'Services',
        redirect: '/services/PSA',
        component: () => import('../views/Services/Services.vue'),
        children: [
            {
              path: '/PSA',
              component: () => import('../views/Services/PSA.vue')
            },
            {
              path: '/PSD',
              component: () => import('../views/Services/PSD.vue')
            }
        ]
    },
    {
        path: '/products',
        name: 'Products',
        redirect: '/products/enerplot',
        component: () => import('../views/Products/Products.vue'),
        children: [
            {
              path: '/products/enerplot',
              component: () => import('../views/Products/Enerplot.vue')
            },{
              path: '/products/face',
              component: () => import('../views/Products/Face.vue')
            },{
              path: '/products/prsim',
              component: () => import('../views/Products/Prsim.vue')
            },{
              path: '/products/pscad',
              component: () => import('../views/Products/Pscad.vue')
            },{
              path: '/products/rtds',
              component: () => import('../views/Products/Rtds.vue')
            },
            {
              path: '/products/rtds/ps',
              component: () => import('../views/Products/RTDS/PS.vue')
            },
            {
              path: '/products/rtds/hvdc',
              component: () => import('../views/Products/RTDS/HVDC.vue')
            },
            {
              path: '/products/rtds/ds',
              component: () => import('../views/Products/RTDS/DS.vue')
            },
            {
              path: '/products/rtds/wampac',
              component: () => import('../views/Products/RTDS/WAMPAC.vue')
            },
            {
              path: '/products/rtds/da',
              component: () => import('../views/Products/RTDS/DA.vue')
            },
            {
              path: '/products/rtds/mre',
              component: () => import('../views/Products/RTDS/MRE.vue')
            },
            {
              path: '/products/rtds/peh',
              component: () => import('../views/Products/RTDS/PEH.vue')
            },
            {
              path: '/products/rtds/ph',
              component: () => import('../views/Products/RTDS/PH.vue')
            },
            {
              path: '/products/rtds/cs',
              component: () => import('../views/Products/RTDS/CS.vue')
            },
            {
              path: '/products/rtds/et',
              component: () => import('../views/Products/RTDS/ET.vue')
            }
            /*

            ,{
              path: '/products/ensi',
              component: () => import('../views/Products/Ensi.vue')
            }
            */
        ],
        
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue')
    },
    { path: "*", component: () => import('../views/404.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
    await checkLanguage()
    store.state.mobileMenu = false;
    next()
})
router.afterEach((to, from) => {
  window.scrollTo(0,0);
})

export default router
