<template>
    <div id="app">
        <Header></Header>
        <transition enter-active-class="animate__animated animate__slideInRight animate__faster" leave-active-class="animate__animated animate__slideOutRight animate__faster">
            <MobileMenu v-if="$store.state.mobileMenu"></MobileMenu>
        </transition>
        <Overlay v-if="$store.state.mobileMenu"></Overlay>
        <div class="router-container">
            <router-view/>
        </div>
        <notifications animation-type="velocity"/>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import FooterContact from '@/components/FooterContact'
import MobileMenu from '@/components/MobileMenu'
import Overlay from '@/components/Overlay'
export default {
    name:'App',
    components:{
        Header,
        Footer,
        MobileMenu,
        Overlay,
        FooterContact
    },
    created () {
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('scroll', this.handleScroll);
	},
    destroyed () {
		window.removeEventListener('resize', this.handleResize);
		window.removeEventListener('scroll', this.handleScroll);
    },
    methods:{
        handleResize (event) {
            this.$store.state.window.pageWidth = window.innerWidth;
            if(window.innerWidth > 960){
                this.$store.state.mobileMenu = false
            }
        },
        handleScroll (event) {
            this.$store.state.window.offsetY = window.scrollY;
            //console.log(this.$store.state.window.offsetY);
        }
    },
    mounted(){
        if(navigator.languages.find(x=> x === 'tr-TR' || x === 'tr')) this.$store.state.languageCode = 'TR'
    },
    watch:{
        '$store.state.languageCode': {
            handler(newVal){
                localStorage.setItem("languageCode", newVal)
                console.log(newVal)
                switch (newVal) {
                    case 'EN':
                        this.$store.state.language = require('./languages/EN.json')
                        break;
                    case 'TR':
                        this.$store.state.language = require('./languages/TR.json')
                        break;
                    default:
                        break;
                }
                
            }
        }
    }
    
}
</script>

<style>
    @import "assets/style/main.css";
    @import "assets/style/animate.css";
    body{
        padding: 0;
        margin: 0;
        font-family: 'Poppins', sans-serif;
    }
    .router-container{
    }
    p{
        text-align: justify !important;
    }
    .languageSelect{
        background-color: transparent;
        color: white;
        padding: 5px 10px;
        border: none;
        font-size: 16px;
        border:0px;
        outline:0px;
            color: #c6c9d8;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    }
    .languageSelect option{
        color: black;
    }
    .page-content img {
        max-width: 600px !important;
    }
</style>
