<template>
    <div class="contact-section">
        <div class="contact-background"></div>
        <div class="inner text-center">
            <span>{{$store.state.language.contactArea.title}}</span>
            <h2>{{$store.state.language.contactArea.bigText}}</h2>
            <a class="rn-button-style--2 cursor-pointer" @click="$router.push('/contact')"><span>{{$store.state.language.contactArea.btn}}</span></a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FooterContact'
}
</script>
<style scoped>
    .contact-section{
        overflow: hidden;
        position: relative;
        background: linear-gradient(145deg, #e4ac16 0%, #ffd666 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 110px 10px !important;
        padding-bottom: 100px !important;
    }

    .contact-section .inner > span {
        color: #fff;
        font-size: 17px;
        text-transform: uppercase;
        letter-spacing: 4px;
        display: block;
        margin-bottom: 9px;
    }
    .contact-section .inner > h2 {
        color: #ffffff;
        font-size: 75px;
        font-weight: 900;
        font-family: 'Montserrat', sans-serif;
        line-height: 90px;
        margin: 0;
    }

    .contact-section .inner .rn-button-style--2 {
        text-decoration: none;
        color: #c6c9d8;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 2px;
        border: 3px solid #b1b4c1;
        padding: 20px 40px;
        border-radius: 6px;
        display: inline-block;
        font-weight: 500;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        border: 3px solid #fff;
        color: #fff;
        margin-top: 30px;
        background: transparent;
    }
    .contact-section .inner .rn-button-style--2:hover {
        color: #e4ac16;
        background-color: white;
        transform: translateY(-5px);
    }

    .contact-section .contact-background:before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(/assets/images/pattern-1.png);
        content: "";
        z-index: 0;
        background-repeat: repeat;
        opacity: .5;
        border-top-right-radius: 6px;
    }
    .contact-section .inner{
        z-index: 2;
    }
</style>