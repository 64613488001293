<template>
    <div class="header animated-normal" :style="$store.state.window.pageWidth < 960 ? 'height: 80px;' : 'height: 170px;'" :class="$store.state.window.offsetY > 20 ? 'down' : ''">
        <div class="headerContent px-4 px-md-12">
            <img class="" alt="LPS logo" :height="$store.state.window.pageWidth < 960 ? '50px' : '170px'" :src="$store.state.window.pageWidth < 960 ? require('../assets/logo-text.png') : require('../assets/logo.png')">
            <button class="mobile-menu cursor-pointer hover-opacity" @click="$store.state.mobileMenu = true" v-if="$store.state.window.pageWidth < 960">
                <i class="mdi mdi-menu"></i>
            </button>
            <div class="menu d-flex align-center justify-space-between" v-else>
                <span class="menu-item"  @click="$router.push('/')">{{$store.state.language.menu.home}}</span>
                <div class="dropdown">
                    <span class="menu-item">{{$store.state.language.menu.services}}</span>
                    <div class="dropdown-content">
                        <span @click="$router.push('/PSA')">{{$store.state.language.psa.title}}</span>
                        <span @click="$router.push('/PSD')">{{$store.state.language.psd.title}}</span>
                    </div>
                </div>
                <div class="dropdown">
                    <span class="menu-item">{{$store.state.language.menu.products}}</span>
                    <div class="dropdown-content">
                        <span @click="$router.push('/products/pscad')">PSCAD</span>
                        <span @click="$router.push('/products/rtds')">RTDS</span>
                        <span @click="$router.push('/products/prsim')">PRSIM</span>
                        <span @click="$router.push('/products/enerplot')">Enerplot</span>
                        <span @click="$router.push('/products/face')">FACE</span>
                        
                        
                        
                        <!--<span @click="$router.push('/products/ensi')">ENSI</span>-->
                    </div>
                </div>
                <span class="menu-item" @click="$router.push('/contact')">{{$store.state.language.menu.contact}}</span>
                <select v-model="$store.state.languageCode" class="languageSelect">
                    <option :value="item.code" v-for="item in $store.state.languages" :key="item.code">{{item.text}}</option>
                </select>
                <div class="lps-button d-flex align-center justify-center" @click="openLPS()">
                    <img :src="require('@/assets/img/lps.png')" width="90px">
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'Header',
    methods:{
        openLPS: function(){
            window.open('https://lpschain.com/', '_blank')
        }
    }
}
</script>
<style scoped>
.header{
    width: 100%;
    background-color: transparent;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    right: 0;
    z-index: 299;
    justify-content: center;
}
.headerContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    flex: 1 1 auto;
}
.header.down{
    box-shadow: 0px 7px 5px  -10px rgba(0,0,0,0.7);
}
.menu{
    align-self: flex-start;
    margin: 50px;
    color: white;
}
.menu .menu-item{
    color: #c6c9d8;
    font-size: 16px;
    font-weight: 500;
    padding: 20px 20px;
    display: inline-block;
    transition: .3s;
    cursor: pointer;
}

.menu .menu-item:hover{
    color: #fabb11 !important;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
    transition: .3s;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    transition: .3s;
    padding: 10px;
    border-radius: 4px;
    flex-direction: column;
}

.dropdown-content span{
    padding: 11px 15px;
    text-decoration: none;
    color: #0b0a0c;
    transition: .3s;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}


/* Change color of dropdown links on hover */
.dropdown-content span:hover {background-color: #ddd; 
    transition: .3s;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: flex; 
    transition: .3s;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #3e8e41; 
    transition: .3s;}

.mobile-menu{
    font-size: 32px;
    border: none;
    background-color: transparent;
    color: #ffffffa8;
    border-radius: 4px;
    outline: none;
}

.lps-button{
    border: solid 1px white;
    padding: 7px 29px;
    border-radius: 50px;
    margin-left: 25px;
    transition: .3s;
    cursor: pointer;
}

.lps-button:hover{
    
    background-color: #ffffff14;

}

</style>