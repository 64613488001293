import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        language: null,
        languageCode: null,
        languages: [
            {
                code: 'EN',
                text: 'English'
            },
            {
                code: 'TR',
                text: 'Türkçe'
            }
        ],
        window: {
            pageWidth: window.innerWidth,
            offsetY: window.scrollY
        },
        mobileMenu: false
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    },
    getters: {
        downloadFile: (state) => (fileName) => {
			var link = document.createElement("a");
            // If you don't know the name or want to use
            // the webserver default set name = ''
            link.setAttribute('download', name);
            link.href = 'https://leanpowersolutions.com/documents/' + fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        getValidationClass: (state) => (_data) => {
			return {'is-invalid': _data.$error }
			//return {'is-invalid': _data.$error, 'is-valid': !_data.$invalid }
		},
    }
})
