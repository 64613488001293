<template>
    <div class="mobileMenuContent d-flex flex-column" >
        <div class="d-flex justify-end pa-5 pb-0">
            <i class="mdi mdi-close menu-close cursor-pointer hover-opacity" @click="$store.state.mobileMenu = false"></i>
        </div>
        <div class="mobile-menu-menu d-flex flex-column pa-5 pt-0">
            <span class="menu-item" @click="$router.push('/')">{{$store.state.language.menu.home}}</span>
            <div class="d-flex flex-column">
                <div class="d-flex align-center justify-space-between" @click="services = !services">
                    <span class="menu-item">{{$store.state.language.menu.services}}</span>
                    <i :class="services ? 'mdi mdi-menu-up' : 'mdi mdi-menu-down'" style="font-size: 33px"></i>
                </div>
                <div  style="overflow:hidden">
                <transition enter-active-class="animate__animated animate__slideInDown animate__faster" leave-active-class="animate__animated animate__slideOutUp animate__faster">
                    <div class="sub-menu d-flex flex-column" v-if="services">
                        <span href="#" @click="$router.push('/PSA')">{{$store.state.language.psa.title}}</span>
                        <span href="#" @click="$router.push('/PSD')">{{$store.state.language.psd.title}}</span>
                    </div>
                </transition>
                </div>
            </div>
            <div class="d-flex flex-column">
                <div class="d-flex align-center justify-space-between" @click="products = !products">
                    <span class="menu-item">{{$store.state.language.menu.products}}</span>
                    <i :class="products ? 'mdi mdi-menu-up' : 'mdi mdi-menu-down'" style="font-size: 33px"></i>
                </div>
                <div  style="overflow:hidden">
                    <transition enter-active-class="animate__animated animate__slideInDown animate__faster" leave-active-class="animate__animated animate__slideOutUp animate__faster">
                        <div class="sub-menu d-flex flex-column" v-if="products">
                            <span @click="$router.push('/products/pscad')">PSCAD</span>
                            <span @click="$router.push('/products/rtds')">RTDS</span>
                            <span @click="$router.push('/products/prsim')">PRSIM</span>
                            <span @click="$router.push('/products/enerplot')">Enerplot</span>
                            <span @click="$router.push('/products/face')">FACE</span>
                            
                            
                            
                            <!--<span @click="$router.push('/products/ensi')">ENSI</span>-->
                        </div>
                    </transition>
                </div>
            </div>
            <span class="menu-item" @click="$router.push('/contact')">{{$store.state.language.menu.contact}}</span>
        </div>
        <div class="spacer"></div>
        
        <select v-model="$store.state.languageCode" class="languageSelect mx-4" style="color: black">
            <option :value="item.code" v-for="item in $store.state.languages" :key="item.code">{{item.text}}</option>
        </select>
        <div class="pa-5 d-flex align-center justify-center">
            <div class="lps-button w-100 d-flex align-center justify-center" @click="openLPS()">
                <img :src="require('@/assets/img/lps-dark.png')" width="90px">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MobileMenu',
    data: () =>{
        return {
            services: false,
            products: false
        }
    },
    methods:{
        openLPS: function(){
            window.open('https://lpschain.com/', '_blank')
        }
    }
}
</script>
<style scoped>
    .mobileMenuContent{
        width: 70%;
        height: 100%;
        background-color: white;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 999999;
        color: #1f1f25;
    }
    .menu-close{
        font-size: 28px;
    }
    .mobile-menu-menu{
        font-size: 17px;
        font-weight: 500;
        margin-top: 10px;
    }
    .mobileMenuContent .menu-item{
        padding: 15px 0px;
    }
    
    .sub-menu {
        padding: 0 20px 25px 20px;
        font-size: 15px;
    }
    .sub-menu span {
        margin-top: 18px;
    }


    .lps-button{
        border: solid 1px #0000001a;
        background-color: #0000000f;
        padding: 7px 29px;
        border-radius: 50px;
        transition: .3s;
        cursor: pointer;
    }

    .lps-button:hover{
        

    }

        
</style>