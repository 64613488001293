import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Notifications from 'vue-notification'
import velocity      from 'velocity-animate'
 
Vue.use(Notifications, { velocity })

import Vuelidate from 'vuelidate'
	Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
