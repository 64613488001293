<template>
    <div class="footer-section">
        <div class="d-flex">
            <div class="d-flex flex-column w-100" style="align-items: center; justify-content: flex-end;">
                <div class="footer-right pa-0" >
                    <div class="d-flex px-4 flex-column flex-md-row"  style="max-width: 1300px">
                        <div class="">
                            <div class="widget px-5">
                                <h3 class="widget-title">{{$store.state.language.footer.aboutUs}}</h3>
                                <p>{{$store.state.language.footer.p1}}
                                    <br><br>
                                    {{$store.state.language.footer.p2}}
                                </p>
                                <div class="divide5"></div>
                                <div class="social d-flex">
                                    <a class="white--text" style="text-decoration: none" href="https://tr.linkedin.com/company/lean-power-solutions" target="_blank"><i class="fab fa-linkedin-in hover-opacity cursor-pointer mr-1 d-flex align-center justify-center fs-18" style="background-color: #3393c1; width: 45px; height: 45px;"></i></a>
                                    <a class="white--text" style="text-decoration: none" href="https://twitter.com/lean_power_s?lang=en" target="_blank"><i class="fab fa-twitter hover-opacity cursor-pointer mx-1 d-flex align-center justify-center fs-18" style="background-color: #5daed5; width: 45px; height: 45px;"></i></a>
                                    <a class="white--text" style="text-decoration: none" href="https://www.instagram.com/lean_power_solutions/" target="_blank"><i class="fab fa-instagram hover-opacity cursor-pointer mx-1 d-flex align-center justify-center fs-18" style="background-color: #962fbf; width: 45px; height: 45px;"></i></a>
                                    
                                </div>
                            </div>
                            <!-- /.widget --> 
                        </div>
                        <div class="mt-5 mt-md-0">
                            <div class="widget px-5">
                                <h3 class="widget-title">{{$store.state.language.footer.getInT}}</h3>
                                <p>{{$store.state.language.footer.p3}}</p>
                                <div class="contact-info">
                                    <i class="fas fa-map-marker-alt mr-2 mb-3"></i> Sultan Selim Mah. Hümeyra Sok. No:7 NEF09, Kat:7 No:146,Kağıthane/Istanbul <br>
                                    <!--<i class="fas fa-phone-alt mr-2 mb-3"></i>+00 (123) 456 78 90 -->
                                    
                                    <br>
                                    <i class="fas fa-envelope mr-2"></i> <a href="mailto:contact@leanpowersolutions.com" class="white--text">contact@leanpowersolutions.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100 text-center py-4" style="background: rgb(41, 41, 44); color: white;">
                    <span>{{$store.state.language.footer.copyright}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>


    .footer-left{
        background: -webkit-linear-gradient(305deg,#f81f01,#ee076e);
        background: linear-gradient(145deg,#826821,#fdc530);;
        padding: 50px 120px 40px;
        z-index: 2;
        position: relative;
        margin-right: -15px;
        border-top-right-radius: 6px;
    }

    .footer-left:before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(/assets/images/pattern-1.png);
        content: "";
        z-index: 1;
        opacity: .5;
        border-top-right-radius: 6px;
    }

    .footer-left .inner {
        z-index: 5;
        position: relative;
    }

    .footer-left .inner>span {
        color: #fff;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 4px;
        display: block;
        margin-bottom: 9px;
    }

    .footer-left .inner h2 {
        color: #fff;
        font-size: 75px;
        font-weight: 900;
        font-family: Montserrat,sans-serif;
        line-height: 90px;
    }

    
    .footer-left .inner a.rn-button-style--2 {
        margin-top: 5px;
        border-color: #fff;
        color: #fff;
    }

    a.rn-button-style--2, button.rn-button-style--2 {
        color: #c6c9d8;
        text-transform: uppercase;
        letter-spacing: 2px;
        border: 2px solid #b1b4c1;
        border-radius: 6px;
        display: inline-block;
        font-weight: 500;
        -webkit-transition: .3s;
        transition: .3s;
        text-decoration: none;
        padding: 10px 30px;
        font-size: 14px;
    }

    .footer-right{
        background-color: #2d2d30;
        color: white;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 60px 0px !important;
    }
</style>