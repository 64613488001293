<template>
    <div class="overlay"></div>
</template>
<script>
export default {
    name:'Overlay'
}
</script>
<style scoped>
    .overlay{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 40000;
        background-color: #000000a6;
    }
</style>